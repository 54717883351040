.album-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  margin-bottom: 55px;
  overflow-x: hidden;
  @media (max-width: 478px) {
    display: flex;
    flex-direction: column;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 2;
    margin-top: 30px;
  }
  &__empty {
    font-size: 16px;
    color: white;
    font-family: 'Main Regular', sans-serif;
    text-align: center;
    grid-column: span 1;
  }
  &__title {
    font-size: 24px;
    color: white;
    font-family: 'Main Bold', sans-serif;
    margin-bottom: 20px;
    grid-column: span 2;
  }
}
.pdcst-detail{
  &__socials{
    width:100%;
    border:1px solid #272727;
    border-radius:10px;
    padding:30px 20px 0 20px;
    margin-bottom:60px;
  }
}