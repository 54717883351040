.lnf {
  width: 694px;
  &__input {
    height: 57px !important;
    background: #181818 !important;
    border: 1px solid #3c3c3c;
  }
  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__error {
    color: #ff4747;
    font-size: 12px;
    padding-right: 20px;
  }
  &__success {
    min-height: 353px;
    font-size: 18px;
    color: #8fff68;
    display: flex;
    justify-content: center;
    font-family: 'Main Regular', sans-serif;
    align-items: center;
    &-msg {
      margin-left: 4px;
    }
  }
  &__alert {
    max-width: 60%;
    font-size: 14px;
    background: url(../../assets/images/warn-icon.svg) no-repeat 0 50%;
    padding-left: 35px;
  }
  @media (max-width: 720px) {
    width: 320px;
    &__buttons {
      display: flex;
      flex-direction: column;
    }
    &__alert {
      width: 100%;
      max-width: 100%;
      text-align: left;
      margin-bottom: 15px;
    }
  }
}
