.mobile-view {
  &__content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(#000, #0b0b0b);
    z-index: 9990;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }
  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    .header__nav-link {
      font-size: 34px;
      margin-left: 13px;
      margin-right: 13px;
      @media (max-width: 440px) {
        font-size: 24px;
      }
    }
  }
  &__nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__icon {
    cursor: pointer;
    position: relative;
    z-index: 9991;
  }
}
