.main-layout {
  width: 100%;
  padding: 0 70px;
  @media (max-width: 600px) {
    padding: 0 16px;
    &__content {
      // width:100%;
      display:flex;
      min-height: calc(100vh - 246px);
    }
  }
}
