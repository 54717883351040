.letter-popup {
  &__textarea {
    width: 100%;
    border: 1px solid #3c3c3c;
    background-color: transparent;
    resize: none;
    height: 200px;
    border-radius: 6px;
    padding: 20px;
    color: #fff;
    outline: none;
    &--simple {
      border: 1px solid #272727;
    }
  }
}
