.create-roles{
    width:100%;
    max-width: 800px;
    padding-bottom:100px;
    &__content{
        margin-top:50px;
        width:100%;
        column-count: 2;
        column-gap: 50px;
        @media (max-width: 650px){
            column-count: 1;
        }
    }
}