.login {
  &Member {
    height: auto;
    padding: 130px 0;
    .login__container {
      justify-content: center;
    }
    .loginForm__title {
      margin-bottom: 40px;
      font-size: 30px;
      font-family: EuroExt;
    }
    .login__column--form {
      overflow: inherit;
      @media (max-width: 1440px) {
        width: 100%;
        max-width: 550px;
      }
    }
    .loginForm {
      @media (max-width: 1440px) {
        width: 100%;
      }
    }
    .loginForm__title {
      @media (max-width: 440px) {
        font-size: 18px;
      }
    }
    .loginForm__actions {
      @media (max-width: 440px) {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

.loginForm {
  &__link {
    &--backTo {
      padding-left: 30px;
      background: url(../../assets/images/backto.svg) no-repeat left center;
    }
  }
  &__input--social {
    // margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.inputWithClose {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}
