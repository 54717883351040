.splitName {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid rgba(143, 255, 104, 0.6);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  &:hover {
    color: #fff;
  }
  @media (max-width: 1100px) {
    width: 60px;
    height: 60px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__empty {
    background: #2a2a2a;
    width: 100%;
    height: 100%;
  }
}
