.album-modal-update{
    input{
        background:transparent;
        width:710px;
        @media (max-width:980px){
            width:100%;
        }
    }
    &__group{
        width:100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__button{
        display: flex;
        gap:5px;
        align-items: center;
    }
}