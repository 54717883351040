.event-block{
    @media (max-width:980px){
        &__container{
            gap:10px;
        }
        &__info{
            grid-template-columns: 1fr auto;
            gap:10px;
        }
        &__item{
            order:0;
        }
        &__date{
            order:1;
        }
        .userInfo__text-title{
            margin-bottom:0;
        }
    }
    @media (max-width:768px){
        &__container{
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 150px 1fr;
        }
        &__avatar{
            grid-row: span 2;
        }
    }
    @media (max-width: 468px) {
        &__container{
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 1fr;
        }
        &__avatar{
            grid-row: span 1;
            width:100%;
            img{
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
        .userInfo__text{
            padding-top:10px;
            max-width: 100%;
        }
        .userInfo__actions{
            margin-top:10px;
        }
    }
}