.color-white {
  color: #fff;
}
.color-gray {
  color: #7c7c7c;
}

.requestVerificationForm {
  // width: 650px;
  .loginForm__container {
    border: 1px solid #272727;
    padding: 30px;
    // width: 650px;
  }
  .approvalForm {
    display: grid;
    grid-template-columns: 35% calc(65% - 10px);
    grid-gap: 10px;
    align-items: start;
  }
  .uploadAvatar__container {
    border: 1px solid #272727;
    padding: 30px;
  }
  &__terms {
    margin-top: -2px;
    font-size: 14px;
    font-family: 'Main Regular', sans-serif;
    &-button {
      cursor: pointer;
      margin-top: 3px;
      margin-left: 19px;
      color: #ff4747;
      text-decoration: underline;
    }
  }
  .loginForm__title {
    font-size: 24px;
    font-family: 'EuroExt';
  }
  .label {
    font-size: 16px;
  }
  @media (max-width: 900px) {
    .approvalForm {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      align-items: start;
    }
    .loginForm__title {
      margin-bottom: 50px;
    }
    &__footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
.loginForm__date {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.identityType {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
  &__item {
    color: #7c7c7c;
    cursor: pointer;
    margin: 0 10px;
    &.active {
      color: #fff;
    }
  }
}

.uploadAvatar {
  overflow: hidden;
  &__text {
    display: block;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
  // .dropPresent__image {
  // width: 100%;
  // }
}

.inputWithClose {
  position: relative;
  input {
    width: 100%;
  }
  &__close {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 24px;
    right: 20px;
    cursor: pointer;
    background: url(../../assets/images/close-red-icon.svg) no-repeat center;
  }
}
