.lf{
    width:100%;
    display: flex;
    flex-direction: column;
    gap:25px;
    &__row{
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap:25px;
    }
    &__col{
        width:100%;
        display: flex;
        flex-direction: column;
    }
    &__name{
        font-size:10px;
        color:#A7A7A7;
        margin-bottom:6px;
    }
    &__value{
        font-size:16px;
        color:#fff;
        font-family: "Main Bold", sans-serif;
    }
    &__text{
        font-size:16px;
        line-height: 22px;
        color:#fff;
        font-family: "Main Regular", sans-serif;
    }
}