.imagecard {
  position: relative;
  background: #0e0e0e;
  cursor: pointer;
  width: 100%;
  height: 100%;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s all;
  }
  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.5s all;
  }
  .hidden {
    opacity: 0;
  }
  border: 2px solid #000;
  transition: all 0.3s ease;
  &:hover {
    border: 2px solid #252525;
  }
}
