.requested {
  background-color: #000;
  display: table;
  padding-right: 10px;
  border-radius: 10px;
  min-width: 190px;
  
  &-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &-image {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-icon {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    background: #1d1d1d url(../../assets/images/date.svg) no-repeat 50%;
  }
  &-content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    &__title {
      font-size: 10px;
      color: #777777;
      transition: all 0.3s ease;
    }
    &__text {
      margin-top: 5px;
      font-size: 16px;
      color: #d4d4d4;
      position: relative;
      font-family: 'Main Bold', sans-serif;
      transition: all 0.3s ease;
      div {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (max-width: 1275px) {
          max-width: 100%;
        }
      }
    }
  }
}
