.event-detail {
  width: 100%;
  max-width: 940px;
  margin: 60px auto 0 auto;
  &__headline {
    font-family: 'EuroExt';
    text-align: left;
    color: #9f9f9f;
    // margin-bottom: 12px;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 0 10px 0;
  }
  &__head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 900px) {
      flex-direction: column-reverse;
      gap: 20px;
    }
  }
  &__title {
    padding-right: 26px;
    color: #fff;
    font-size: 28px;
    font-family: 'EuroExt';
    white-space: pre-wrap;
    &-stages {
      &--studio {
        margin-top: 20px;
        margin-top: 60px;
      }
    }
    @media (max-width: 900px) {
      padding: 0;
      font-size: 18px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  &__date {
    display: block;
    // margin: 11px 0 82px 0;
    margin: 10px 0;
    font-size: 18px;
    color: #fff;
    font-family: Arial;
    text-transform: capitalize;
    opacity: 0.6;
    @media (max-width: 900px) {
      // margin: 30px 0 40px 0;
      margin: 30px 0;
    }
  }
  &__p {
    margin-top: 15px;
  }
  &__image {
    width: 380px;
    height: 380px;
    flex-shrink: 0;
    @media (max-width: 900px) {
      width: 100%;
      height: 84vw;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    width: 100%;
    margin-top: 60px;
  }
  &__button {
    font-size: 16px;
    color: #fff;
    background: #ff2525;
    transition: all 0.3s ease;
    border-radius: 6px;
    border: none;
    outline: none;
    font-family: EuroExt;
    cursor: pointer;
    white-space: nowrap;
    display: table;
    // width: 225px;
    width: 150px;
    text-align: center;
    margin-top: 40px;

    // padding: 17px 0 17px 27px;
    padding: 15px;
    &:hover {
      background: #ff2525;
      // padding-left: 32px;
    }
  }
  &__subtitle {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    margin: 10px 0;
    font-family: 'Main Bold';
  }
  &__paragraph {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 20px;
    font-family: 'Inter';
    line-height: 140%;
    max-width: 100%;
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }
  &__others {
    width: 100%;
    margin-top: 90px;
    &-title {
      font-size: 28px;
      font-family: EuroExt;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      margin-bottom: 100px;
    }
    &-content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 290px);
      grid-auto-rows: 290px;
      gap: 36px;
    }
  }
  @media (max-width: 900px) {
    margin-top: 0;
    &__content {
      gap: 20px;
    }
    &__others {
      &-title {
        margin-bottom: 20px;
      }
      .imagecard {
      }
      &-content {
        grid-template-columns: repeat(1, 100%);
        grid-template-rows: repeat(3, 83vw);
      }
    }
  }
  @media (max-width: 900px) {
    &__others {
      &-title {
        font-size: 18px;
      }
    }
  }
  &__title {
    @media (max-width: 440px) {
      font-size: 24px;
    }
    @media (max-width: 900px) {
      font-size: 24px;
    }
  }
}
