.notes {
  width: 400px;
  color: #fff;
  @media (max-width: 1275px) {
    width: 300px;
  }
  @media (max-width: 980px) {
    width: 100%;
  }
  &-title {
    color: #fff;
    font-size: 16px;
    font-family: 'Main Bold', sans-serif;
    margin: 25px 0 20px 0;
  }
  &Flex {
    display: flex;
    &--jcfe {
      justify-content: flex-end;
    }
  }
  &Form {
    &__error {
      color: #ff3a3a;
      font-size: 10px;
      margin-top: 5px;
    }
    &__numbers {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;
    }
    &__num {
      border-radius: 30px;
      font-size: 12px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-family: 'Main Bold', sans-serif;
      transition: all 0.3s ease;
      cursor: pointer;
      &.active {
        border: 2px solid #fff;
        color: #fff;
      }
      &--1 {
        background-color: #ff5555;
        &:hover {
          background-color: #ff3d3d;
        }
      }
      &--2 {
        background-color: #ff7b51;
        &:hover {
          background-color: #ff6534;
        }
      }
      &--3 {
        background-color: #ffc42e;
        &:hover {
          background-color: #ffb800;
        }
      }
      &--4 {
        background-color: #b1ff32;
        &:hover {
          background-color: #92ec00;
        }
      }
      &--5 {
        background-color: #19fe14;
        &:hover {
          background-color: #04ce00;
        }
      }
    }
    &__description {
      border: 1px solid #4b4b4b;
      color: #fff;
      font-size: 12px;
      min-height: 76px;
      outline: none;
      background-color: transparent;
      width: 100%;
      border-radius: 10px;
      padding: 15px 17px;
      margin-top: 20px;
      resize: none;
      &::placeholder {
        color: #868686;
      }
    }
    &__btn {
      border: none;
      padding: 0 20px;
      color: #fff;
      height: 34px;
      font-size: 14px;
      border-radius: 30px;
      background-color: #ff4747;
      cursor: pointer;
      margin-top: 15px;
      transition: all 0.3s ease;
      font-family: 'Main Bold', sans-serif;
      &:hover {
        background-color: #ff3a3a;
      }
    }
  }
}

.archive {
  &Item {
    margin-bottom: 15px;
    border: 1px solid #252525;
    padding: 20px;
    border-radius: 6px;
    &__date {
      color: #fff;
      opacity: 0.5;
      font-size: 12px;
      margin-top: 10px;
    }
    &__empty {
      text-align: center;
      color: #fff;
      padding: 20px 0;
    }
    &__description {
      font-size: 14px;
      color: #ffffff;
      padding-right: 30px;
      position: relative;
      line-height: 18px;
      word-wrap: break-word;
    }
    &__vote {
      width: 23px;
      height: 23px;
      border-radius: 23px;
      font-size: 12px;
      // position: absolute;
      // right: 0;
      // top: 50%;
      // transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Main Bold', sans-serif;
      color: #000;
      &--1 {
        background-color: #ff5555;
      }
      &--2 {
        background-color: #ff7b51;
      }
      &--3 {
        background-color: #ffc42e;
      }
      &--4 {
        background-color: #b1ff32;
      }
      &--5 {
        background-color: #19fe14;
      }
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      &-noted {
        font-size: 13px;
        margin-left: 10px;
        color: #9c9c9c;
        &__name {
          display: inline-block;
          margin-left: 5px;
          color: #c8c8c8;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  &__more {
    background-color: #0c0c0c;
    font-size: 16px;
    height: 50px;
    border-radius: 10px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: #1c1c1c;
      margin-top: 10px;
    }
  }
}
