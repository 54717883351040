.userMiniInfo {
  border: 1px solid #272727;
  border-radius: 6px;
  color: #fff;
  padding: 30px 20px;
  max-width: 300px;
  word-wrap: break-word;
  span {
    font-family: 'Main Bold', sans-serif;
  }
  @media (max-width: 1100px) {
    display: none;
  }
}
