.reqVerificationPage {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-gap: 10px;
  // padding: 0 30px;
  align-items: start;
  @media (max-width: 1100px) {
    grid-template-columns: 100% 0;
  }
}
