// @font-face {
//   font-family: 'Main Regular';
//   src: url('../../fonts/SFTSchriftedSansTRIAL-Regular.eot');
//   src: url('../../fonts/SFTSchriftedSansTRIAL-Regular.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SFTSchriftedSansTRIAL-Regular.woff2') format('woff2'),
//     url('../../fonts/SFTSchriftedSansTRIAL-Regular.woff') format('woff'),
//     url('../../fonts/SFTSchriftedSansTRIAL-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Main Bold';
//   src: url('../../fonts/SFTSchriftedSansTRIAL-Bold.eot');
//   src: url('../../fonts/SFTSchriftedSansTRIAL-Bold.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SFTSchriftedSansTRIAL-Bold.woff2') format('woff2'),
//     url('../../fonts/SFTSchriftedSansTRIAL-Bold.woff') format('woff'),
//     url('../../fonts/SFTSchriftedSansTRIAL-Bold.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'EuroExt';
  src: url('../../fonts/design.collection1.Euro-Ext-Bold.eot');
  src: 
    url('../../fonts/design.collection1.Euro-Ext-Bold.woff') format('woff'),
    url('../../fonts/design.collection1.Euro-Ext-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Main Regular';
  src: url('../../fonts/SpoofTrial-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Main Bold';
  src: url('../../fonts/SpoofTrial-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Main Light';
  src: url('../../fonts/SpoofTrial-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter_24pt-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Bold';
  src: url('../../fonts/Inter_24pt-Bold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Semi';
  src: url('../../fonts/Inter_24pt-SemiBold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter Medium';
  src: url('../../fonts/Inter_24pt-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: 'EuroExt';
//   src: url('../../fonts/design.collection1.Euro-Ext-Bold.ttf') format('truetype');
// }

html,
body {
  font-family: 'Main Regular', sans-serif;
}
