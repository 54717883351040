@import './components/reset.scss';
@import './components/fonts.scss';

* {
  box-sizing: border-box;
}

html,
body {
  background-color: #000;
}
body {
  width: 100vw;
  overflow-x: hidden;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.title {
  font-size: 24px;
  font-family: 'Main Bold', sans-serif;
  margin-bottom: 35px;
  color: #fff;
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.sr-only {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.error-message {
  color: #ff4747;
  font-size: 12px;
  margin-bottom: 15px;
}

.collapse {
  color: #fff;
  padding-right: 25px;
  display: table;
  height: 20px;
  cursor: pointer;
  &:after {
    content: url('../images/collapse.svg');
    padding-left: 10px;
    display: inline-table;
    transition: all 0.3s ease;
  }
  &-close {
    &:after {
      transform: rotate(180deg);
      padding-left: 0;
      padding-right: 10px;
      transform-origin: center center;
    }
  }
}
button {
  outline: none;
  border: none;
  background-color: transparent;
}

.w-50p {
  width: 50%;
}
.flex {
  display: flex;
  &-jcsb {
    justify-content: space-between;
  }
  &-aic {
    align-items: center;
  }
  &-jcfe {
    justify-content: flex-end;
  }
  &-jcc {
    justify-content: center;
  }
  &-row {
    flex-direction: row;
  }
  &-jcfe {
    justify-content: flex-end;
  }
}

.ask-for-letter {
  font-size: 16px;
  color: #5834a7;
  background: url(../images/upload-icon.svg) no-repeat left 12px center;
  height: 40px;
  line-height: 40px;
  border: 1px solid #5834a7;
  border-radius: 40px;
  padding-left: 45px;
  padding-right: 12px;
  font-family: 'Main Bold', sans-serif;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  display: table;
  &:hover {
    color: #855be2;
    border-color: #855be2;
    background: url(../images/upload-icon-active.svg) no-repeat left 12px center;
  }
}

.letter-popup {
  width: 500px;
  color: #fff;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.styledCheckbox {
  font-family: system-ui, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  cursor: pointer;
  color: #fff;
  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
    color: #fff !important;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    box-shadow: inset 1em 1em white;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
    color: #fff !important;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1) translate(-50%, -50%);
  }
  &--terms {
    text-decoration: underline;
  }
  &--error {
    color: #ff4747;
  }
}

.color-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.mt-20 {
  margin-top: 20px;
}
.mr-10 {
  margin-right: 10px;
}

.go-to-profile {
  padding-left: 25px;
  background: url(../images/go-to-profile.svg) no-repeat left center;
  background-size: 15px 16px;
}

.text {
  color: #fff;
  &--bold {
    font-family: 'Main Bold', sans-serif;
  }
  &--lg {
    font-size: 32px;
  }
  &--md {
  }
  &--sm {
    font-size: 18px;
  }
  &--underline {
    text-decoration: underline;
  }
  &--center {
    text-align: center;
  }
  &--scale {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  &--error {
    color: #ff4747;
  }
}

.dropPresent {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #272727;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #272727;
  font-size: 12px;
  position: relative;

  padding-top: 50px;
  overflow: hidden;
  z-index: 1;
  position: relative;
  &--event {
    height: 150px;
    width: 100%;
    border-radius: 6px;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: 0;
  }
  p {
    z-index: 1;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    transition: all 0.3s ease;
    z-index: 1;
    &:before {
      content: '';
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      background: url(../images/upload.svg) no-repeat 50% 40%;
    }
  }
  &:hover {
    cursor: pointer;
    // p {
    //   // transform: scale(1.05);
    // }
  }
}

.profileContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 45px;
  @media (max-width: 900px) {
    margin-top: 0;
  }
}

.no-data {
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.bold {
  font-family: 'Main Bold', sans-serif;
}

.text {
  &--readable {
    font-size: 14px;
    line-height: 18px;
  }
  &--white {
    color: #fff;
  }
  @media (max-width: 900px) {
    &--readable {
      font-size: 18px;
      line-height: 20px;
    }
  }
}

.wmde-markdown {
  background-color: transparent !important;
}

.required-field {
  color: #ff4747;
  font-size: inherit !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.cur-p {
  cursor: pointer;
}

.text-upper {
  text-transform: uppercase;
}

.main-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 1400px) {
    width: auto;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}

input::-webkit-date-and-time-value {
  text-align: left !important;
}

.pos-r {
  position: relative;
}
