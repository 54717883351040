.statistics {
  display: grid;
  grid-template-columns: 1fr 1.2fr 0.5fr;
  grid-gap: 10px;
  @media (max-width: 980px) {
    grid-template-columns: 1fr 1.2fr;
    padding-bottom:50px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 300px;
    &:last-child{
      @media (max-width: 980px) {
        grid-column: span 2;
        height:150px;
      }
    }
  }
  &__item {
    background-color: red;
    border-radius: 10px;
    padding-left: 35px;
    padding-bottom: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    color: #fff;
    flex-direction: column;
    transition: all 0.3s ease;
    @media (max-width: 980px) {
      height:150px;
      padding-left: 15px;
      padding-bottom: 10px;
    }

    &-title {
      font-size: 16px;
    }
    &-count {
      font-size: 54px;
      font-family: 'Main Bold';
    }

    &--verified {
      background-color: #5dbe74;
      height: 45%;
      &:hover {
        background-color: #2f9347;
      }
    }
    &--pending {
      background-color: #e3d45a;
      height: 55%;
      &:hover {
        background-color: #95892e;
      }
    }

    &--banned {
      height: 65%;
      background-color: #e1765a;
      &:hover {
        background-color: #9b462f;
      }
    }
    &--requested {
      height: 35%;
      background-color: #855be2;
      &:hover {
        background-color: #5c36b0;
      }
    }

    &--all {
      background-color: #8f9a9d;
      height: 100%;
      &:hover {
        background-color: #6d787b;
      }
    }
  }
}
