.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__search {
    outline: none;
    border: none;
    height: 30px;
    background: url(../../../assets/images/search-icon.svg) no-repeat left
      center;
    padding-left: 30px;
    color: #fff;
    transition: all 0.3s ease;
    width: 200px;
    &:focus {
      width: 300px;
      border-bottom: 1px solid #fff;
    }
  }
  &__item {
    font-size: 16px;
    color: #808080;
    margin: 0 15px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &:hover,
    &.active {
      color: #fff;
    }
  }
}
.content{
  &__title{
    @media (max-width:980px){
      width:100%;
      display: flex!important;
      align-items: center!important;
      justify-content: space-between!important;
      input{
        max-width: 200px;
      }
    }
    @media (max-width:478px){
      width:100%;
      align-items: flex-start!important;
      flex-direction: column;
      gap:20px;
      input{
        max-width: 100%;
      }
    }
  }
}