.a-overview{
    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:100%;
        margin-bottom: 35px;
        .collapse{
            padding-right:0;
        }
        h1{
            margin-bottom: 0;
        }
    }
}