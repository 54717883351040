.podcasts-detail {
  width: 100%;
  margin: 90px auto 0 auto;
  max-width: 940px;
  display: flex;  
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  &__cover {
    width: 370px;
    flex-shrink: 0;
    margin-right: 30px;
    
    @media (max-width: 900px) {
      float: initial;
      margin: 0;
    }
    @media (max-width: 700px) {
      width: 100%;
    }
  }
  &__info{
    width:100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height:539px;
    @media (max-width: 900px){
      min-height:auto;
    }
    // padding-bottom:78px;
  }
  &__image {
    width: 100%;
    height: 370px;
    display: flex;
    align-items: flex-start;
    @media (max-width: 700px) {
      // height: auto;
      height: calc(85.5vw + 0px);
    }
  }
  &__name {
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 28px;
    font-family: EuroExt;
    color: #fff;
    text-transform: uppercase;
    @media (max-width: 440px) {
      margin: 0 0 10px 0;
      font-size: 18px;
    }
  }
  &__social {
    display: inline-block;
    width: 50px;
    height: 18px;
    &--soundcloud {
      background: url('../../../assets/images/soundcloud-icon-gray.svg')
        no-repeat 50%;
      &:hover {
        background: url('../../../assets/images/soundcloud-icon-white.svg')
          no-repeat 50%;
      }
    }
    &--instagram {
      background: url('../../../assets/images/instagram-icon-gray.svg')
        no-repeat 50%;
      &:hover {
        background: url('../../../assets/images/instagram-icon-white.svg')
          no-repeat 50%;
      }
    }
    &--ra {
      background: url('../../../assets/images/ra-icon-gray.svg') no-repeat 50%;
      &:hover {
        background: url('../../../assets/images/ra-icon-white.svg') no-repeat
          50%;
      }
    }
    &:hover {
      transition: all 0.3s ease;
      border-color: #fff;
    }
  }
  &__title {
    @media (max-width: 440px) {
      font-size: 24px;
    }
    @media (max-width: 900px) {
      font-size: 24px;
    }
  }
  &__bio {
    line-height: 140%;
    color: #fff;
    font-size: 18px;
    flex: 1;
    margin-bottom: 20px;
    font-family: 'Inter';
  }
  &__tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
  }
  &__clear {
    clear: both;
  }
  &__tag {
    color: #acacac;
    font-size: 14px;
    border-radius: 50px;
  }
  &__others {
    width: 100%;
    margin: 80px auto 0 auto;
    max-width: 940px;
    &-title {
      font-size: 28px;
      font-family: EuroExt;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      margin-bottom: 60px;
    }
    &-content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 290px);
      gap: 36px;
    }
    &-card {
      width: 100%;
      height: 100%;
    }
    &-cover {
      height: 290px;
    }
    &-name {
      font-size: 24px;
      color: #fff;
      margin-top: 28px;
      font-family: 'Main Bold';
    }
  }
  

  &__list{
    width:100%;
    max-width: 940px;
    margin:130px auto 180px auto;
    &-body{
      width:100%;
      display: flex;
      flex-direction: column;
      gap:70px;
    }
    &-title{
      width:100%;
      text-align: center;
      font-size: 28px;
      line-height: 34px;
      font-family: 'EuroExt';
      color:#fff;
      text-transform: uppercase;
      margin-bottom: 55px;
    }
  }
  &__artist{
    width:100%;
    display: flex;
    gap:24px;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    &-cover{
      position: relative;
      width:210px;
      height:210px;
      flex-shrink: 0;
      @media (max-width: 700px) {
        width:100%;
        height:auto;
      }
      &:hover .podcasts-detail__artist-hover{
        opacity:1;
      }
    }
    &-hover{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background:rgba(0,0,0,0.5);
      z-index: 2;
      transition:0.5s all;
      opacity:0;
    }
    &-info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-title{
      text-transform: uppercase;
      font-family: 'Main Bold';
      color:#fff;
      font-size:24px;
      margin-bottom: 10px;
    }
    &-name{
      text-transform: uppercase;
      font-family: 'Main Bold';
      color:#fff;
      font-size:20px;
      margin-bottom: 20px;
      opacity: 0.5;
    }
    &-desc{
      width:100%;
      font-size:16px;
      line-height: 22px;
      color:#fff;
      font-family: "Inter";
      padding-bottom:20px;
    }
  }

  @media (max-width: 900px) {
    &__others {
      &-cover {
        height: 84vw;
      }
      &-title {
        font-size: 18px;
        margin-bottom: 20px;
      }
      &-name {
        margin-top: 15px;
      }
      &-content {
        grid-template-columns: repeat(1, 100%);
      }
    }
    &__list-title{
      font-size:22px;
      line-height: 30px;
      white-space: normal;
      word-spacing: 0;
    }
  }
}
