.nw-userInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 1275px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  @media (max-width: 468px) {
    flex-direction: column-reverse;
  }
  &:hover {
    .requested-content__title {
      color: #949494;
    }
    .requested-content__text {
      color: #fff;
    }
  }

  &Container {
    display: grid;
    grid-template-columns: 80% 20%;
    height: 100px;
    border: 1px solid #353535;
    border-radius: 10px;
    padding: 0px 25px;
    background-color: #141414;
    width: 100%;
    transition: all 0.3s ease;
    @media (max-width: 980px) {
      grid-template-columns: 60% 40%;
      height: 130px;
      padding: 15px 20px;
    }
    @media (max-width: 468px) {
      grid-template-columns: 100%;
      display: flex;
      height: initial;
      min-height: 130px;
      flex-direction: column;
      justify-content: center;
      padding: 0 0 15px 0;
    }
    &:hover {
      background-color: #0c0c0c;
      border: 1px solid #474747;
    }
  }
  &__avatar {
    width: 64px;
    height: 64px;
    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
    }
    @media (max-width: 468px) {
      width: 100%;
      height: 300px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
    &--lg {
      width: 150px;
    }
  }
  &__text {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .resident-country {
      font-family: 'Main Regular', sans-serif;
    }
    @media (max-width: 768px) {
      display: none;
      &--mobile {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
    @media (max-width: 468px) {
      &--mobile {
        padding: 0 20px;
        margin-top: 20px;
      }
    }
    &--lg {
      max-width: 350px;
    }
    &-title {
      color: #fff;
      font-size: 10px;
      margin-bottom: 5px;
    }
    &-text {
      color: #fff;
      font-size: 16px;
      font-family: 'Main Bold', sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (max-width: 768px) {
        max-width: 100%;
        line-height: 25px;
      }
    }
  }
  &__container {
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 468px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 468px) {
      padding: 0 20px;
      margin-top: 35px;
      & > button {
        width: 100%;
      }
    }
  }
  &-social {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
    &--facebook {
      background: url(../../assets/images/fb-icon.png) no-repeat center;
    }
    &--instagram {
      background: url(../../assets/images/insta-icon.png) no-repeat center;
    }
    &--default {
      background: url(../../assets/images/default-social-icon.svg) no-repeat
        center;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #1a1919;
}
// ბუძი ამას იმიტომ ვამატებ რომ სხვა ყველა ფეიჯზე ეს კომპონენტი გვაქვს ივენთებზე, ლოსთფაუნდზე და აშ და იმათ სტილი არ აქვთ
.userInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  &__reg{
    line-height: 25px;
    font-weight: 200;
    font-family: 'Main Regular', sans-serif;
  }
  @media (max-width: 1275px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  @media (max-width: 468px) {
    flex-direction: column-reverse;
  }
  &:hover {
    .requested-content__title {
      color: #949494;
    }
    .requested-content__text {
      color: #fff;
    }
  }

  &Container {
    display: grid;
    grid-template-columns: 80% 20%;
    height: 100px;
    border: 1px solid #353535;
    border-radius: 10px;
    padding: 0px 25px;
    background-color: #141414;
    width: 100%;
    transition: all 0.3s ease;
    @media (max-width: 980px) {
      grid-template-columns: 60% 40%;
      height: 130px;
      padding: 15px 20px;
    }
    @media (max-width: 468px) {
      grid-template-columns: 100%;
      display: flex;
      height: initial;
      min-height: 130px;
      flex-direction: column;
      justify-content: center;
      padding: 0 0 15px 0;
    }
    &:hover {
      background-color: #0c0c0c;
      border: 1px solid #474747;
    }
  }
  &__avatar {
    width: 64px;
    height: 64px;
    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
    }
    @media (max-width: 468px) {
      width: 100%;
      height: 300px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
    &--lg {
      width: 150px;
    }
  }
  &__text {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .resident-country {
      font-family: 'Main Regular', sans-serif;
    }
    &--fluid{
      max-width: initial;
    }
    @media (max-width: 768px) {
      // display: none;
      overflow: hidden;
      width:100%;
      &--mobile {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
    @media (max-width: 468px) {
      padding:20px 20px 0 20px;
      &--mobile {
        padding: 0 20px;
        margin-top: 20px;
      }
    }
    &--lg {
      max-width: 350px;
    }
    &-title {
      color: #fff;
      font-size: 10px;
      margin-bottom: 5px;
    }
    &-text {
      color: #fff;
      font-size: 16px;
      font-family: 'Main Bold', sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (max-width: 768px) {
        max-width: 100%;
        line-height: 25px;
      }
    }
  }
  &__container {
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 468px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 468px) {
      padding: 0 20px;
      margin-top: 35px;
      & > button {
        width: 100%;
      }
    }
  }
  &-social {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    &--active{
      border:2px solid #FFC221;
    }
    &:hover {
      transform: scale(1.1);
    }
    &--facebook {
      background: url(../../assets/images/fb-icon.png) no-repeat center;
    }
    &--instagram {
      background: url(../../assets/images/insta-icon.png) no-repeat center;
    }
    &--default {
      background: url(../../assets/images/default-social-icon.svg) no-repeat
        center;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #1a1919;
}
