.statusChange {
  &__text {
    font-size: 16px;
    color: #fff;
    padding: 30px;
    text-align: center;
    padding-bottom: 40px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  &__button {
    height: 40px;
    padding: 0 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bcbcbc;
    background-color: #202020;
    transition: all 0.3s ease;
    outline: none;
    border: 1px solid #7d7d7d;
    border-radius: 40px;
    cursor: pointer;
    font-family: 'Main Bold', sans-serif;
    &:hover {
      border-color: #d4d4d4;
      background-color: #d4d4d4;
      color: #1d1d1d;
    }
  }
}
