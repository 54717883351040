.public-residents {
  width: 100%;
  &__header {
    padding: 40px 0 80px 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
    color: #fff;
    font-family: EuroExt;
    @media (max-width: 440px) {
      font-size: 18px;
      padding: 20px 0 50px 0;
    }
  }
  &__content {
    width: 100%;
    display: grid;
    justify-content: center;
    // grid-template-columns: repeat(4, 1fr);
    // grid-auto-rows: calc(23.5vw + 50px);
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: calc(26vw);
    gap: 60px 20px;
    @media (max-width: 1380px) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: calc(30.5vw + 50px);
    }
    @media (max-width: 1020px) {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: calc(44.5vw + 50px);
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-auto-rows: calc(85.5vw + 50px);
      gap: 42px;
    }
  }
  &__card {
    // overflow: hidden;
    &-cover {
      display: flex;
      width: 100%;
      height: calc(100% - 50px);
    }
    &-title {
      margin-top: 26px;
      font-size: 24px;
      font-family: 'Main Bold';
      color: #fff;
      text-transform: uppercase;
      @media (max-width: 600px) {
        margin-top: 15px;
        font-size: 24px;
      }
    }
  }
  &__loading {
    grid-column: 4 span;
  }
}
