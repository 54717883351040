.loader {
  // margin-top: 150px;
  margin: 100px 0;
  &--full {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--sm {
    margin-top: 40px;
  }
}

.dots {
  width: 98.6px;
  height: 13.4px;
  margin: 0 auto;
  background: radial-gradient(farthest-side, #fff 90%, #0000) 25% 0,
    radial-gradient(farthest-side, #fff 90%, #0000) 75% 0;
  background-size: 13.4px 13.4px;
  background-repeat: no-repeat;
  position: relative;
  animation: dots-q4kofwmd 1s linear infinite;
}

.dots:before {
  content: '';
  position: absolute;
  width: 13.4px;
  height: 13.4px;
  border-radius: 50%;
  background: #fff;
  inset: 0;
  margin: auto;
  animation: dots-svj0l9md 1s cubic-bezier(0.5, 300, 0.5, -300) infinite;
}

@keyframes dots-q4kofwmd {
  0%,
  24% {
    background-position: 25% 0, 75% 0;
  }

  40% {
    background-position: 25% 0, 85% 0;
  }

  50%,
  72% {
    background-position: 25% 0, 75% 0;
  }

  90% {
    background-position: 15% 0, 75% 0;
  }

  100% {
    background-position: 25% 0, 75% 0;
  }
}

@keyframes dots-svj0l9md {
  100% {
    transform: translate(0.11px);
  }
}
