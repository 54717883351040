.checkbox{
    width: 16px;
    height:16px;
    input[type="checkbox"] {
        cursor: pointer;
        width: inherit;
        height:inherit;
        margin:0;
        background:transparent;
        border:1px solid #8F7F7F;
        appearance: none;
        transition: 0.3s all;
    }
      
    input[type="checkbox"]:checked{
       background:#31BF00;
       border:1px solid #fff;
    }
}