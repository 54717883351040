.public-header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__logo {
    width: 215px;
    height: 42px;
    overflow: auto;
    // background: url(../../assets/images/logo.png) no-repeat center;
    display: block;
    background-size: contain;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 9991;
    @media (max-width: 600px) {
      height: 25px;
      width: 129px;
      overflow: hidden;
    }
  }
  &__nav {
    &-link {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: 13px;
      margin-right: 13px;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      // color: rgb(122 122 122);
      // fill: #7b7a7a;

      font-size: 18px;
      letter-spacing: 0.36px;
      font-family: 'EuroExt';
      &:hover {
        transition: 0.3s all;
        // color: #fff;
        // fill: #fff;
        color: #7b7a7a;
      }
      &.active {
        // color: #fff;
        // fill: #fff;
        color: #7b7a7a;
      }
    }
  }
  &__logout {
    width: 20px;
    height: 30px;
    cursor: pointer;
    background: url(../../assets/images/logout.svg) no-repeat center;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.2);
    }
  }
  &__profile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    @media (max-width: 1100px) {
      order: -1;
    }
  }
}
