.house-rules {
  width: 100%;
  color: #fff;
  &__title {
    font-size: 28px;
    color: #fff;
    font-family: 'EuroExt';
    margin-bottom: 28px;
    @media (max-width: 440px) {
      font-size: 22px;
    }
    &--sm {
      font-size: 24px;
      line-height: 30px;
      @media (max-width: 440px) {
        font-size: 18px;
      }
    }
  }
  &__item {
    width: 100%;
    margin-bottom: 55px;
    &--sm {
      margin-bottom: 0;
    }
  }
  &__subtitle {
    font-size: 24px;
    color: #fff;
    font-family: 'Main Bold';
    margin-bottom: 20px;
    @media (max-width: 440px) {
      font-size: 18px;
    }
    &--sm {
      font-size: 18px;
      line-height: 18px;
      @media (max-width: 440px) {
        font-size: 16px;
      }
    }
  }
  &__text {
    font-size: 18px;
    color: #fff;
  }
  &__list {
    line-height: 30px;
    padding-left: 10px;
    font-size: 18px;
    font-family: 'Inter';
    @media (max-width: 440px) {
      font-size: 16px;
    }
    &:before {
      display: inline-block;
      padding: 0 10px 0 0;
      content: '•';
    }
  }
}
