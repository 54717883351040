.lf{
    @media (max-width:980px){
        max-width: initial;
    }
    @media (max-width:768px){
        .userInfoContainer{
            display: grid;
            grid-template-columns: 1fr;
            gap:10px;
            height:auto;
        }
        .userInfo__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }
    }
    
}