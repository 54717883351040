.ra-codes{
    width:100%;
    &__nav{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap:32px;
        &-body{
            width:100%;
            display: flex;
            align-items: center;
            gap:32px;
        }
        &-item{
            cursor: pointer;
            color:#808080;
            &.active{
                color:#fff;
            }
        }
    }
    &__nowrap{
        white-space: nowrap;
    }
    &__body{
        width:100%;
        border-top:1px solid #1A1919;
        border-bottom:1px solid #1A1919;
        margin: 20px 0 20px 0;
        padding:20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &__block{
        width:100%;
        height:60px;
        border-radius:10px;
        background-color: #141414;
        border:1px solid #353535;
        padding:0 25px;
        font-size:16px;
        color:#fff;
        font-family: 'Main Bold';
        display: flex;
        align-items: center;
        &> span{
            font-family: 'Main Regular';
            padding-left:4px;
        }
    }
    &__form{
        &-body{
            width:610px;
        }
        &-button{
            max-width: fit-content;
            margin-left: auto;
        }
    }
    &__status{
        width:610px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap:17px;
        padding:50px 0 80px 0;
        &-msg{
            font-size:24px;
            color:#fff;
            font-family: "Main Regular";
            text-align: center;
        }
    }
}