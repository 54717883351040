.resident-detail {
  width: 100%;
  margin: 90px auto 0 auto;
  max-width: 940px;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  &__cover {
    width: 370px;
    flex-shrink: 0;
    float: left;
    margin-right: 30px;
    @media (max-width: 900px) {
      float: initial;
      margin: 0;
    }
    @media (max-width: 700px) {
      width: 100%;
    }
  }
  &__image {
    width: 100%;
    height: 455px;
    display: flex;
    align-items: flex-start;
    @media (max-width: 700px) {
      // height: auto;
      height: calc(85.5vw + 0px);
    }
  }
  &__name {
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 28px;
    font-family: EuroExt;
    color: #fff;
    text-transform: uppercase;
    @media (max-width: 440px) {
      margin: 0 0 10px 0;
      font-size: 18px;
    }
  }
  &__social {
    display: inline-block;
    width: 65px;
    height: 36px;
    margin-right: 16px;
    &--soundcloud {
      background: url('../../../assets/images/soundcloud-icon-gray.svg')
        no-repeat 50%;
      &:hover {
        background: url('../../../assets/images/soundcloud-icon-white.svg')
          no-repeat 50%;
      }
    }
    &--instagram {
      background: url('../../../assets/images/instagram-icon-gray.svg')
        no-repeat 50%;
      &:hover {
        background: url('../../../assets/images/instagram-icon-white.svg')
          no-repeat 50%;
      }
    }
    &--ra {
      background: url('../../../assets/images/ra-icon-gray.svg') no-repeat 50%;
      &:hover {
        background: url('../../../assets/images/ra-icon-white.svg') no-repeat
          50%;
      }
    }
    &:hover {
      transition: all 0.3s ease;
      border-color: #fff;
    }
  }
  &__title {
    @media (max-width: 440px) {
      font-size: 24px;
    }
    @media (max-width: 900px) {
      font-size: 24px;
    }
  }
  &__bio {
    line-height: 140%;
    color: #fff;
    font-size: 18px;
    flex: 1;
    font-family: "Inter";
  }
  &__tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
  }
  &__clear {
    clear: both;
  }
  &__tag {
    // padding: 8px 24px;
    color: #acacac;
    font-size: 14px;
    border-radius: 50px;
    border: 1px solid #707070;
    // display: block;
    // width: min-content;
  }
  &__others {
    width: 100%;
    margin: 80px auto 0 auto;
    max-width: 940px;
    &-title {
      font-size: 28px;
      font-family: EuroExt;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      margin-bottom: 60px;
    }
    &-content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 290px);
      gap: 36px;
    }
    &-card {
      width: 100%;
      height: 100%;
    }
    &-cover {
      height: 340px;
    }
    &-name {
      font-size: 24px;
      color: #fff;
      margin-top: 28px;
      font-family: 'Main Bold';
    }
  }
  @media (max-width: 900px) {
    &__others {
      &-cover {
        height: 84vw;
      }
      &-title {
        font-size: 18px;
        margin-bottom: 20px;
      }
      &-name {
        margin-top: 15px;
      }
      &-content {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }
}
