.user-roles{
    &__title{
        font-size:16px;
        color:#fff;
        font-family: 'Main Bold',sans-serif;
        padding:10px 20px 0 20px;
    }
    &__detail-btn{
        margin-top:20px;
    }
}