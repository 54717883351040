.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #fff;
  margin-top: 115px;
  padding: 55px 0;
  font-family: EuroExt;
  @media (max-width: 1000px) {
    margin: 0;
    padding: 100px 0 0 0;
    flex-direction: column;
    padding-bottom: 20px;
    text-align: center;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  &__col {
    width: 100%;
    &--center {
      display: flex;
      justify-content: center;
      gap: 25px;
    }
    &--right {
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
    @media (max-width: 900px) {
      // display: none;
      text-align: center;
      &--right {
        text-align: center;
        justify-content: center;
      }
    }
  }
}
