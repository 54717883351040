.membersProfile {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  &__overlay{
    position:absolute;
    top:0;
    left:0;
    background:rgba(0,0,0,0.7);
    width:100%;
    height:100%;
    z-index:100;
  }
  @media (max-width: 980px) {
    flex-direction: column;
    padding-bottom:60px;
  }
  &-info {
    width: calc(100% - 400px);
    label.member-role-select {
      font-size: 10px !important;
      padding-left: 0 !important;
    }
    @media (max-width: 1275px) {
      width: calc(100% - 300px);
    }
    @media (max-width: 980px) {
      width: 100%;
    }
  }
  &__actions {
    display: flex;
    margin: 25px 0;
    padding-top: 25px;
    justify-content: space-between;
    border-top: 1px solid #292929;
    @media (max-width: 768px) {
      flex-direction: column;
      gap:20px;
      &-requested{
        flex-direction: column;
      }
    }
    &-btns {
      display: flex;
      gap: 20px;
      @media (max-width: 768px) {
        justify-content: space-between;
        width:100%;
        & > button{
          width:100%;
        }
      }
      @media (max-width: 390px) {
        flex-direction: column;
        & > button{
          width:100%;
        }
      }
    }
  }
  &-score {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px solid rgba(71, 255, 145, 0.5);
    border-radius: 40px;
    padding: 0 20px;
    font-family: 'Main Bold', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    color: #47ff91;
    &--1 {
      border: 1px solid #ff5555;
      color: #ff5555;
    }
    &--2 {
      border: 1px solid #ff7b51;
      color: #ff7b51;
    }
    &--3 {
      border: 1px solid #ffc42e;
      color: #ffc42e;
    }
    &--4 {
      border: 1px solid #b1ff32;
      color: #b1ff32;
    }
    &--5 {
      border: 1px solid #19fe14;
      color: #19fe14;
    }
  }
}
