.login {
  width: 100%;
  height: 100vh;
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    @media (max-width: 980px) {
      flex-direction: column;
      position: relative;
      padding-bottom: 60px;
    }
  }
  &__column {
    &--preview {
      width: 45%;
      height: 100%;
      background: url(../../../assets/images/login-bg.png) no-repeat 50%;
      background-size: cover;
      position: relative;
      padding: 90px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
      }
      @media (max-width: 980px) {
        background: none;
        width: 100%;
        height: 100px;
        padding: 0px 20px;
        position: initial;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
          display: none;
        }
      }
    }
    &--form {
      width: 55%;
      height: 100%;
      overflow: auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: 1275px) {
        .loginForm {
          width: 100%;
          padding: 0 80px;
        }
      }
      @media (max-width: 980px) {
        width: 100%;
        height: calc(100% - 100px - 50px);
        .loginForm {
          width: 100%;
          padding: 0px 20px;
        }
      }
    }
  }
  &__logo {
    position: relative;
    z-index: 1;
    width: 253px;
    height: 50px;
    background: url(../../../assets/images/logo.png) no-repeat 50%;
    background-size: contain;
    @media (max-width: 980px) {
      width: 174px;
      height: 41px;
    }
  }
  .content-by {
    color: #fff;
    font-size: 14px;
    position: relative;
    z-index: 1;
    a {
      color: #fff;
    }
    @media (max-width: 980px) {
      position: absolute;
      bottom: 30px;
      left: 20px;
      opacity: 0.5;
    }
  }
  &Form {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;

    &__container {
      width: 100%;
    }
    &__title {
      color: #fff;
      font-size: 32px;
      font-family: 'Main Bold', sans-serif;
      margin-bottom: 90px;
      @media (max-width: 980px) {
        font-size: 24px;
        // text-align: center;
        margin-bottom: 40px;
      }
    }
    &__input {
      // margin-bottom: 40px;
      width: 100%;
      &--noRounded {
        border-radius: 6px !important;
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__link {
      color: #fff;
      font-size: 14px;
      @media (max-width: 980px) {
        font-size:12px;
      }
      &--login {
        display: flex;
        height: 52px;
        font-family: 'Main Bold', sans-serif;
        align-items: center;
        padding-left: 30px;
        position: relative;
        transition: all 0.3s ease;
        background-color: #ff3131;
        color: #fff;
        border-radius: 6px;
        text-transform: uppercase;
        padding-right: 30px;
        &:hover {
          padding-right: 30px;
          background-color: #ff4747;
        }
        &:active {
          transform: scale(1.05);
        }
      }
    }
  }
}
