.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__search {
    outline: none;
    border: none;
    height: 30px;
    background: url(../../../assets/images/search-icon.svg) no-repeat left
      center;
    padding-left: 30px;
    color: #fff;
    transition: all 0.3s ease;
    width: 200px;
    &:focus {
      width: 300px;
      border-bottom: 1px solid #fff;
    }
  }
  &__item {
    font-size: 16px;
    color: #808080;
    margin: 0 15px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &:hover,
    &.active {
      color: #fff;
    }
  }
}
