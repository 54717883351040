.role-module{
    &__module{
        width: 100%;
        max-width: 350px;
        height: fit-content;
        margin-bottom: 40px;
        @media (max-width: 650px){
            max-width: initial;
        }
        &-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size:16px;
            color:#fff;
            margin-bottom: 22px;
        }
    }
    &__body{
        display: flex;
        flex-direction: column ;
        gap:14px;
    }
    &__role{
        position: relative;
        padding-left:25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size:16px;
        color:#989696;
        &.active{
            color:#fff;
        }
        cursor: pointer;
        &:before{
            position:absolute;
            top:50%;
            left:11px;
            transform: translate(0,-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            content: "";
            width:3px;
            height:3px;
            border-radius:100%;
            background:#fff;
        }
    }
}