.events {
  width: 100%;
  &__header {
    padding: 40px 0 80px 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
    color: #fff;
    font-family: EuroExt;
    @media (max-width: 440px) {
      font-size: 18px;
      padding: 20px 0 50px 0;
    }
  }
  &__nav {
    display: flex;
    justify-content: flex-end;
  }
  &__searchbox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    background: transparent url(../../../assets/images/searchbox.svg) no-repeat
      20px 50%;
    height: 35px;
    border-radius: 36px;
    outline: none;
    padding-left: 45px;
    color: #fff;
    max-width: 350px;
    border: 1px solid #272727;
    @media (max-width: 440px) {
      max-width: 100%;
    }
  }
  &__content {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    // grid-auto-rows: 28vw;
    // grid-auto-rows: 400px;
    grid-auto-rows: auto;
    gap: 25px 25px;
    @media (max-width: 1195px) {
      grid-template-columns: repeat(2, 1fr);
      //   grid-auto-rows: 41vw;
      grid-auto-rows: auto;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-auto-rows: 84vw;
    }
    @media (max-width: 440px) {
      gap: 42px;
    }
  }
  &__loading {
    grid-column: 3 span;
    @media (max-width: 1195px) {
      grid-column: 2 span;
    }
    @media (max-width: 600px) {
      grid-column: 1 span;
    }
  }
}
