.sidebar {
  width: 215px;
  border-right: 1px solid #1a1919;
  height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
  @media (max-width: 1275px) {
    background: black;
    position: fixed;
    z-index: 99;
    width: 260px;
    transform: translate(-100%, 0);
    &--open {
      transition: all 0.3s ease;
    }
  }
  &__logo {
    width: 100%;
    height: 100px;
    background: url(../../../assets/images/logo.png) no-repeat center;
    display: block;
    background-size: 145px;
  }
  &__menu {
    text-decoration: none;
    color: #fff;
    display: flex;
    justify-content: center;

    &__container {
      width: 70%;
    }
    &-title {
      font-size: 8px;
      padding-top: 10px;
    }
    &-item {
      text-decoration: none;
      color: #7b7a7a;
      display: block;
      margin: 20px 0;
      padding: 5px 0 5px 30px;
      padding-left: 30px;
      &--dashboard {
        background: url(../../../assets/images/dashboard-gray.svg) no-repeat
          left;
      }
      &--invites {
        background: url(../../../assets/images/invites-icon-gray.svg) no-repeat
          left;
      }
      &--members {
        background: url(../../../assets/images/user-gray.svg) no-repeat left;
      }
      &--events {
        background: url(../../../assets/images/calendar-gray.svg) no-repeat left;
      }
      &--podcasts {
        background: url(../../../assets/images/podcasts-gray.svg) no-repeat left;
      }
      &--residents {
        background: url(../../../assets/images/star-gray.svg) no-repeat left;
      }
      &--content {
        background: url(../../../assets/images/document-gray.svg) no-repeat left;
      }
      &--lostandfound {
        background: url(../../../assets/images/lostandfound-gray.svg) no-repeat
          left;
      }
      &--ra-codes {
        background: url(../../../assets/images/ra-invite-icon-gray.svg)
          no-repeat left;
      }
      &--roles {
        background: url(../../../assets/images/user-roles-gray.svg) no-repeat
          left;
      }

      transition: all 0.3s ease;
      &:hover,
      &.active-link {
        color: #fff;
        &.sidebar__menu-item--dashboard {
          background: url(../../../assets/images/dashboard.svg) no-repeat left;
        }
        &.sidebar__menu-item--invites {
          background: url(../../../assets/images/invites-icon.svg) no-repeat
            left;
        }
        &.sidebar__menu-item--members {
          background: url(../../../assets/images/user.svg) no-repeat left;
        }
        &.sidebar__menu-item--events {
          background: url(../../../assets/images/calendar.svg) no-repeat left;
        }
        &.sidebar__menu-item--podcasts {
          background: url(../../../assets/images/podcasts.svg) no-repeat left;
        }
        &.sidebar__menu-item--residents {
          background: url(../../../assets/images/star.svg) no-repeat left;
        }
        &.sidebar__menu-item--content {
          background: url(../../../assets/images/document.svg) no-repeat left;
        }
        &.sidebar__menu-item--lostandfound {
          background: url(../../../assets/images/lostandfound.svg) no-repeat
            left;
        }
        &.sidebar__menu-item--ra-codes {
          background: url(../../../assets/images/ra-invite-icon.svg) no-repeat
            left;
        }
        &.sidebar__menu-item--roles {
          background: url(../../../assets/images/user-roles.svg) no-repeat left;
        }
      }
      // @media (max-width:1275px){
      //   &:not(.sidebar__menu-item--members){
      //     display:none;
      //   }
      // }
    }
  }
}
