.activate-account{
    &__title{
        font-size:32px;
        font-family: 'EuroExt';
    }
    &__button{
        font-size:18px;
        color:#FF3131;
        font-family: "EuroExt";
    }
}