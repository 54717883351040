.state-switch{
    height:40px;
    // background:#8f9a9d;
    border-radius:20px;
    cursor: pointer;
    padding:4px;
    width: min-content;
    display: flex;
    gap:2px;
    padding:4px;
    &__item{
        height: 100%;
        text-wrap: nowrap;
        border-radius:20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:0 10px;
        color:#8f9a9d;
        transition: 0.3s all;
        &.not-found.active{
            background:#8f9a9d;
            color:white;
        }
        &.found.active{
            background:#5dbe74;
            color:white;
        }
        &.lost.active{
            background:rgb(255 71 71);
            color:white;
        }
        
    }
    @media (max-width:468px){
        width:100%;
        justify-content: space-between;
    }
}