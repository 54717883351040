.form-double {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  & > div {
    width: 50%;
  }
  input {
    width: 100%;
  }
  @media (max-width:980px){
    flex-direction: column;
    & > div {
      width: 100%!important;
    }
  }
}

.react-datepicker__input-container input {
  background: #000;
  border: 1px solid #272727;
  height: 64px;
  color: #fff;
  border-radius: 6px;
  padding: 0px 27px;
  outline: none;
  @media (max-width:980px){
    height: 48px;
    padding: 0px 15px;
  }
}
