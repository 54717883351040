.album-card {
  width: 100%;
  height: 185px;
  display: flex;
  gap: 20px;
  border: 1px solid #272727;
  border-radius: 6px;
  padding: 8px;
  grid-column: 1;
  @media (max-width: 478px) {
    height: auto;
    flex-direction: column;
    gap: 0px;
    padding:0 0 8px 0;
  }
  &__image {
    width: 169px;
    height: 169px;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 478px) {
      width:100%;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  &__info {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    @media (max-width: 478px) {
      padding: 15px 10px 0 10px;
    }
  }
  &__title {
    font-family: 'Main Bold';
    color: #fff;
    font-size: 24px;
  }
  &__artist {
    font-family: 'Main Bold';
    color: #fff;
    font-size: 20px;
    opacity:0.5;
  }
  &__description {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-family: 'Main Regular';
  }
  &__delete{
    width: 100%;
    display: flex;
    gap:20px;
    align-items: center;
  }
}
